.App {
    max-width: 1440px;
    margin-inline: auto;
}

.page-container {
    max-width: 1144px;
    margin: 0 auto;

    hr {
        border: 1px solid #000000;
    }
}

.wrapper__anchor {
    position: absolute;
    right: 0;
    height: 100%;
}

.anchor {
    display: flex;
    height: max-content;
    position: sticky;
    top: 10px;
    right: 0;

    &__items {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;
    }

    &__item {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
        transition: 0.2s;
        text-decoration: none;
        color: #000000;
    }
    .active {
        font-weight: 700;
        color: $blue;
    }
}

.body {
    position: relative;
}

.section {
    margin-bottom: 38px;
    &__title {
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        margin-bottom: 18px;
        text-align: left;
        // max-width: 810px;
        margin-inline: auto;
    }
    .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        // max-width: 810px;
        margin-inline: auto;
    }

    .description_news {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 810px;
        margin-inline: auto;
    }
}

.icon-bot-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    transform: translateY(16px);

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: #019147;
    }
}

.outline {
    max-width: 810px;
    margin-inline: auto;
    
    img {
        transform: translateX(-80px);
    }
}

.outline__image img {
    max-width: 100%;  /* make sure image doesn't grow larger than its container */
    height: auto;     /* maintain the image aspect ratio */
    display: block;   /* remove any margins around the image */
    margin: 0 auto;   /* center the image if it's smaller than the screen width */
}



.outline__image {
    margin-bottom: 5vh;
    // margin-left: 5vw;

}

.outline {
    max-width: 90vw; /* ensures that the container doesn't exceed the screen width */
    overflow: hidden; /* hide any child elements that might spill over the container */
}

.reading img {
    width: auto;
    max-width: 100%;
    height: auto;
}


.example {
    .row__button {
        display: flex;
        justify-content: center;
        margin-inline: auto;
        gap: 16px;
    }

    .reading {
        height: fit-content;

        .wrapper__reading {
            max-width: 810px;
            margin-inline: auto;
        }

        &__title {
            font-size: 30px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0em;
            text-align: center;
            margin: 17px auto 14px;
            color: $blue;
            
        }

        .image-primary,
        .image-sub {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-inline: auto;
        }

        .image-sub {
            display: none;
            transition: 0.8s;
        }

        .wrapper__toggle {
            position: relative;

            .reading__toggle {
                position: absolute;
                top: 7px;
                left: 10px;
                z-index: 10;
            }

            &.active {
                .image-sub {
                    display: block;
                }
            }
        }
    }
}



.slick-dots li button:before {
    font-size: 14px !important;
}

.slick-dots li.slick-active button:before {
    color: #9fd2f1 !important;
}

.control {
    &__items {
        display: flex;
        gap: 30px;
        margin: 34px auto;
        width: fit-content;
    }

    .image_conversation {
        max-width: 810px;
        margin: 0 auto;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .icon-svg {
        max-height: 45px;
        object-fit: cover;
    }

    .btn-prev {
        cursor: pointer;
        text-align: center;
        margin-bottom: 10px;
    }
}

.example {
    .wrapper-slick {
        position: relative;
        margin: 0 10px;

        .slick-dots {
            padding: 20px 0;
            bottom: -60px;
        }
    }

    .btn-slick {
        position: absolute;
        top: 50%;
        z-index: 10;
        cursor: pointer;

        &.next {
            right: 0;
        }
        &.prev {
            left: 0;
        }
    }
}

.box-item-control {
    .item {
        width: 120px;
        aspect-ratio: 1/1;
        border: 4px solid $green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: 0.2s;
        cursor: pointer;

        &.active {
            background-color: $green;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 100px;
        text-align: center;
        padding-top: 14px;
    }
}

.icon-github {
    width: 40px;
    height: 40px;
}
.icon-adobe {
    width: 35px;
    height: 35px;
}



.slick-slider.box-item-control {
    .slick-slide {
        padding-inline: 10px;
    }
    .item {
        width: 100px !important;
        p {
            font-size: 13px;
        }
    }
}

.box-slider {
    margin: 18px 0;
}

.box-item-control.box-slider {
    display: none;
}


/* For Mobile Portrait View */
@media screen and (max-width: 1024px) {
    
    .body {
        padding-bottom: 70px; /* Adjust as needed */
        max-width: 90%;
    }

    .outline__image {
        margin-bottom: 0vh;
        margin-left: 0vh;
    
    }
    .slick-slider.box-item-control {
        .slick-slide {
            padding-inline: 10px;
        }
        .item {
            width: 70px !important;
            height: 70px !important;
            p {
                font-size: 8px;
                margin-bottom: 2px;

            }
        }
    }
    .control .icon-svg {
        max-height: 30px;
        margin-bottom: 10px;

    }

    .example .reading__title {
        font-size: 20px;
    }
    .icon-github {
        width: 30px; /* 1/3 of 90 */
        height: 31.67px; /* 1/3 of 95 */
    }

    .icon-adobe {
        width: 21px;
        height: 30px;
    }

    .page-container {
        padding: 0 25px;
    }

    .section .description {
        // text-align: justify;
    }

    .section__title {
        font-size: 24px;
    }

    #outline > div > img {
        width: 100%;
        transform: unset;
    }

    .anchor {
        display: none;
    }

    .slick-slider.slick-initialized {
        .slick-arrow {
            display: none !important;
        }
    }

    .control__items {
        gap: 5px;

        .item {
            width: 105px;
            p {
                font-size: 13px;
            }
        }
    }

    .example {
        .wrapper-slick {
            margin: 0;
            .slick-dots {
                padding: 25px 0;
                bottom: -72px;
            }

            .btn-slick {
                bottom: -65px;
                top: unset;

                &.next {
                    right: 35%;
                }
                &.prev {
                    left: 35%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .control__items.box-item-control {
        display: none;
    }

    .box-item-control.box-slider {
        display: flex;
    }

    .example {
        .wrapper-slick {
            .btn-slick {
                &.next {
                    right: 20%;
                }
                &.prev {
                    left: 20%;
                }
            }
        }
    }
}
