.custom-divider .ant-divider-inner-text {
    color: inherit;
    font-size: 25px;
}
@media screen and (max-width: 1024px) {
    .custom-divider .ant-divider-inner-text {
        color: inherit;
        font-size: 18px;
    }
    
}
.custom-divider.ant-divider-horizontal.ant-divider-with-text-left::before,
.custom-divider.ant-divider-horizontal.ant-divider-with-text-left::after {
    border-top: 3px solid #93B3A5;
}

.custom-divider.ant-divider-horizontal.ant-divider-with-text-center::before,
.custom-divider.ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 3px solid #93B3A5;
}

.citation {
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-top: 20px;
    padding: 20px;
    line-height: 1.6;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
  
  .citation pre {
    font-family: inherit;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .citation-line {
    display: block;
    padding-left: 2em;
    text-indent: -2em;
  }